import { Box, type CardProps, styled } from "@mui/material";
import { Tooltip } from "@pimo/pimo-components";
import type { FC } from "react";

export type IconCellProps = {
  value: string;
  title?: string;
  cardProps?: CardProps;
};

const OverviewTableCell = styled(Box)({
  display: "block",
  padding: "0 20px",
});

export const IconCell: FC<IconCellProps> = ({
  cardProps = {},
  value,
  title,
}) => {
  return (
    <Tooltip title={title}>
      <OverviewTableCell sx={{ ...cardProps.sx }}>
        <Box
          component={"img"}
          src={value}
          sx={{
            width: 25,
            height: 25,
            margin: "auto",
            display: "block",
          }}
        ></Box>
      </OverviewTableCell>
    </Tooltip>
  );
};
