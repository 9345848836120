import {
  NavigateBeforeRounded,
  NavigateNextRounded,
} from "@mui/icons-material";
import {
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { Box } from "@mui/system";
import { LegendPerItemProps } from "@mui/x-charts/ChartsLegend/LegendPerItem";
import type { PimoReactComponent } from "@pimo/pimo-app-builder/dist";
import {
  MuiLineChartCard,
  type MuiLineChartCardProps,
} from "@pimo/pimo-components";
import { getQuarterAndYear } from "in-utils";
import { useEffect, useMemo, useState } from "react";
import {
  convertToYearlyData,
  generateQuarters,
} from "../../app/helpers/chart-data-helpers.ts";

export type LineChartSerie = {
  id: string;
  label: string;
  data: (number | null)[];
  color?: string;
  connectNulls?: boolean;
  curve?: "linear" | "natural";
  showMark?: (params: { index: number }) => boolean;
};

const AMOUNT_OF_QUARTERS_SHOWN = 7;
const AMOUNT_OF_YEARS_SHOWN = 8;
const SCALES = [
  { value: "quarterly", label: "Quarterly" },
  { value: "yearly", label: "Yearly" },
] as const;

export const LineChartCard: PimoReactComponent<
  MuiLineChartCardProps & {
    initialQuarter: string;
    initialYear: string;
  }
> = (props) => {
  const [allSeries, setAllSeries] = useState<LineChartSerie[]>([]);

  // array holding how the displayed series should be filtered.
  const [filterSeriesBy, setFilterSeriesBy] = useState<string[]>(
    props.series.map(({ label }) => label)
  );
  // all Series filtered by "filterSeriesBy"
  const [filteredSeries, setFilteredSeries] = useState<LineChartSerie[]>([]);

  const [viewState, setViewState] = useState({
    year: "",
    quarter: "",
    scale: "quarterly" as "quarterly" | "yearly",
  });
  const [forecastLimit, setForecastLimit] = useState<number | undefined>(
    undefined
  );
  const [isNextYear, setIsNextYear] = useState(false);
  const [isPreviousYear, setIsPreviousYear] = useState(false);
  const [maxValue, setMaxValue] = useState(0);
  const memoizedSeries = useMemo(() => props.series, [props.series]);
  const seriesMaxLength = Math.max(
    ...memoizedSeries.map((seriesData) => seriesData?.data?.length || 0)
  );

  const [currentQuarter, currentYear] = getQuarterAndYear();

  useEffect(() => {
    const [quarter, year] = getQuarterAndYear(new Date(), -3);
    setViewState((prevState) => ({
      ...prevState,
      year: year,
      quarter: quarter,
    }));
  }, []);

  // Update the data displayed in the line chart (seriesToDisplay) and calculate whether navigating to the previous or next year is possible
  useEffect(() => {
    if (!viewState.year || !viewState.quarter) return;

    const currentViewStartQuarterYear =
      viewState.quarter + " " + viewState.year;

    const seriesQuarters = generateQuarters(
      props.initialQuarter,
      props.initialYear,
      seriesMaxLength
    );
    // Determine the index of the currently displayed start quarter in the overall data series
    const startQuarterIndex = seriesQuarters.indexOf(
      currentViewStartQuarterYear
    );

    const currentQuarterAsIndexInSeries = seriesQuarters.indexOf(
      `${currentQuarter} ${currentYear}`
    );

    if (viewState.scale === "quarterly") {
      const isSeriesBeforeCurrentDate =
        viewState.year < currentYear ||
        (viewState.year === currentYear && viewState.quarter < currentQuarter);
      setForecastLimit(
        currentQuarterAsIndexInSeries === -1
          ? isSeriesBeforeCurrentDate
            ? seriesQuarters.length - 1
            : 0
          : currentQuarterAsIndexInSeries - startQuarterIndex < 0
            ? 0
            : currentQuarterAsIndexInSeries - startQuarterIndex
      );
    } else {
      setForecastLimit(+currentYear - +props.initialYear);
    }

    // Update the currently shown data series based on the viewState.scale and startQuarterIndex
    const updatedSeries = memoizedSeries.map((seriesData) => {
      let limitedData;
      if (viewState.scale === "yearly") {
        limitedData = convertToYearlyData(
          seriesData.data,
          props.initialQuarter
        );
      } else {
        limitedData =
          startQuarterIndex !== -1
            ? seriesData.data.slice(
                startQuarterIndex,
                startQuarterIndex + AMOUNT_OF_QUARTERS_SHOWN
              )
            : [];
      }
      return { ...seriesData, data: limitedData };
    });

    setAllSeries(updatedSeries);
    setFilteredSeries(
      [...updatedSeries].filter((series) =>
        filterSeriesBy.includes(series.label)
      )
    );

    // Determine if navigation to the previous and the next year is possible
    setIsPreviousYear(startQuarterIndex >= 4);
    setIsNextYear(startQuarterIndex + 4 < seriesQuarters.length);
  }, [
    viewState,
    memoizedSeries,
    props.initialQuarter,
    props.initialYear,
    seriesMaxLength,
    filterSeriesBy,
    currentYear,
    currentQuarter,
  ]);

  // Calculate and update the maximum value across all series data points in seriesToDisplay
  useEffect(() => {
    let maxValue = 1;
    allSeries.forEach((seriesData) => {
      seriesData.data?.forEach((value) => {
        maxValue = Math.max(maxValue, value || 0);
      });
    });
    setMaxValue(maxValue);
  }, [allSeries]);

  const handleScaleChange = (event: SelectChangeEvent<unknown>) => {
    const newScale = event.target.value as "yearly" | "quarterly";
    setViewState((prevState) => ({ ...prevState, scale: newScale }));
  };

  const handlePrevYear = () => {
    if (!isPreviousYear) return;
    setViewState((prevState) => ({
      ...prevState,
      year: (parseInt(prevState.year, 10) - 1).toString(),
    }));
  };

  const handleNextYear = () => {
    if (!isNextYear) return;
    setViewState((prevState) => ({
      ...prevState,
      year: (parseInt(prevState.year, 10) + 1).toString(),
    }));
  };

  const labelsToDisplay =
    viewState.scale === "quarterly"
      ? generateQuarters(
          viewState.quarter,
          viewState.year,
          AMOUNT_OF_QUARTERS_SHOWN
        )
      : Array.from({ length: AMOUNT_OF_YEARS_SHOWN }, (_, index) =>
          (parseInt(props.initialYear) + index).toString()
        );

  const yAxisProps = [
    {
      min: 0,
      max: Math.ceil(maxValue + maxValue * 0.1),
      tickSize: 1,
      label: "Impact (Mn Euro)",
    },
  ];

  const xAxisProps = [
    {
      tickSize: 1,
      tickMaxStep: 1,
      tickMinStep: 1,
      label: "",
      data: Array.from(
        {
          length:
            viewState.scale === "quarterly"
              ? AMOUNT_OF_QUARTERS_SHOWN
              : AMOUNT_OF_YEARS_SHOWN,
        },
        (_, index) => index
      ),
      valueFormatter: (val: number) => {
        return labelsToDisplay[val];
      },
    },
  ];

  const currentPeriodLabel = `${labelsToDisplay[0]} - ${
    labelsToDisplay[AMOUNT_OF_QUARTERS_SHOWN - 1]
  }`;

  const legendItems: LegendPerItemProps["itemsToDisplay"] = [
    { color: "none", label: "planned     ", id: "plannedLabel" },
    { color: "none", label: "actual     ", id: "actualLabel" },
    ...filteredSeries.map((s) => ({
      color: s.color ?? "",
      label: s.label,
      id: s.id,
    })),
  ];

  return (
    <MuiLineChartCard
      title="Impact Planned / Actual"
      series={filteredSeries}
      cardProps={{
        ...props.cardProps,
        leftSlot: (
          <Box
            component="img"
            src="maturity.svg"
            sx={{
              backgroundColor: "white",
              borderRadius: 2,
            }}
          />
        ),
        rightSlot: (
          <FormControl>
            <InputLabel>Series:</InputLabel>
            <Select
              multiple
              value={filterSeriesBy}
              input={<OutlinedInput label="Series" />}
              renderValue={(selected) => {
                if (selected.length === props.series.length) {
                  return "All";
                }

                return selected.join(", ");
              }}
              sx={{
                width: "300px",
              }}
              onChange={(event) => {
                const {
                  target: { value },
                } = event;

                if (typeof value === "string") {
                  return;
                }

                setFilterSeriesBy(value);
              }}
            >
              {allSeries.map((series) => (
                <MenuItem key={series.id} value={series.label}>
                  <Checkbox
                    checked={filterSeriesBy.indexOf(series.label) > -1}
                  />
                  <ListItemText primary={series.label} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        ),
      }}
      xAxis={xAxisProps}
      yAxis={yAxisProps}
      forecastLimit={forecastLimit}
      legendItems={legendItems}
      sx={{
        ".MuiChartsLegend-root": {
          ".MuiChartsLegend-series:nth-of-type(-n+2)": {
            ".MuiChartsLegend-mark": {
              y: "0 !important",
              height: "0.1px",
              width: "20px",
              stroke: "black",
              strokeWidth: "1px",
            },
          },
          ".MuiChartsLegend-series:nth-of-type(1)": {
            ".MuiChartsLegend-mark": {
              strokeDasharray: "5 5",
              strokeDashoffset: "2.5px",
            },
          },
        },
      }}
    >
      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <Box sx={{ minWidth: 120 }}>
          <Box sx={{ display: "flex", alignItems: "center", gap: 5 }}>
            {viewState.scale === "quarterly" && (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 0,
                  minWidth: "190px",
                }}
              >
                <NavigateBeforeRounded
                  sx={{
                    cursor: isPreviousYear ? "pointer" : "auto",
                    color: isPreviousYear ? "black" : "lightgray",
                  }}
                  onClick={handlePrevYear}
                />
                <Box>{currentPeriodLabel}</Box>
                <NavigateNextRounded
                  sx={{
                    cursor: isNextYear ? "pointer" : "auto",
                    color: isNextYear ? "black" : "lightgray",
                  }}
                  onClick={handleNextYear}
                />
              </Box>
            )}
            <FormControl fullWidth>
              <Select
                value={viewState.scale}
                onChange={handleScaleChange}
                sx={{
                  width: 120,
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    border: "none",
                  },
                  ".MuiOutlinedInput-notchedOutline": {
                    border: "none",
                  },
                }}
              >
                {SCALES.map((scale) => (
                  <MenuItem key={scale.value} value={scale.value}>
                    {scale.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Box>
      </Box>
    </MuiLineChartCard>
  );
};
