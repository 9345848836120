import { Close, FilterAlt } from "@mui/icons-material";
import { Box, Divider, FormControl, Typography, useTheme } from "@mui/material";
import { DateFilter, DropdownFilter } from "@pimo/pimo-components";
import { InitiativeFilterData, InitiativeFilterOptions } from "in-types";
import { FC } from "react";

export interface InitiativesStatusTableFilterDialogProps {
  onClick?: () => void;
  handleChange: (
    event: React.ChangeEvent<HTMLInputElement> | { target: { value: string } },
    trigger: string
  ) => void;
  filterData: InitiativeFilterData;
  filterOptions: InitiativeFilterOptions;
}

export const InitiativesStatusTableFilterDialog: FC<
  InitiativesStatusTableFilterDialogProps
> = ({ onClick, handleChange, filterData, filterOptions }) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        p: 1,
        gap: 0,
        width: 300,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "start",
            width: "100%",
            pr: 1,
          }}
        >
          <FilterAlt
            sx={{ color: theme.palette.primary.main, height: "35px" }}
          />
          <Typography sx={{ fontWeight: 500 }}>Filter</Typography>
        </Box>
        <Close onClick={onClick}></Close>
      </Box>
      <Divider
        sx={{
          bgcolor: theme.palette.secondary.main,
          height: "1px",
          borderWidth: 0,
        }}
      />
      <FormControl
        component="fieldset"
        sx={{ display: "flex", flexDirection: "column", gap: 1 }}
      >
        {/* Text Filter, year */}
        <DateFilter
          views={["year"]}
          value={filterData.yearFilter ?? ""}
          label="Year"
          triggerIdentifier="yearFilter"
          handleChange={handleChange}
        />
        <Divider
          sx={{
            bgcolor: theme.palette.secondary.main,
            height: "1px",
            borderWidth: 0,
          }}
        />
        {/* Dropdown phase */}
        <DropdownFilter
          options={filterOptions.phase ?? []}
          value={filterData.phaseFilter ?? []}
          label="Initiative Phase"
          handleChange={handleChange}
          triggerIdentifier={"phaseFilter"}
        />
        <Divider
          sx={{
            bgcolor: theme.palette.secondary.main,
            height: "1px",
            borderWidth: 0,
          }}
        />
        {/* Dropdown update status */}
        <DropdownFilter
          options={filterOptions.updateStatus ?? []}
          value={filterData.updateStatusFilter ?? []}
          label="Update Status"
          handleChange={handleChange}
          triggerIdentifier={"updateStatusFilter"}
        />
      </FormControl>
    </Box>
  );
};
