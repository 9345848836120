import { App, Plugin, Route, View } from "@pimo/pimo-app-builder";
import { TabLayout, TabsLayoutProps } from "@pimo/pimo-components";
import {
  BffOe,
  BffOeInitiatives,
  CommentsByYearAndQuarter,
  EditInitiativesResponse,
  InitiativeFilterData,
  InitiativeSortData,
} from "in-types";

import {
  type LockDetails,
  ResourceLockPlugin,
} from "@pimo/resource-lock-plugin";
import { LOCK_RESOURCE_NAMES } from "in-utils";
import { INFormConfirmationDialogType } from "../../components/in-form-confirmation-dialog.tsx";
import { buildCommentsFormRoute } from "../../form/build-comments-form-route.tsx";
import { buildFormRoute } from "../../form/build-form-route.tsx";
import { buildImpactPlanFormRoute } from "../../form/build-impact-plan-form-route.ts";
import { InAppState } from "../app.tsx";
import { APP_ROUTES } from "../constants";
import {
  fetchOE,
  fetchOEComments,
  fetchOEInitiatives,
} from "../helpers/fetch-helpers.ts";
import { buildDashboardView } from "./initiative-plugin-views/dashboard-view.tsx";
import { buildProductivityImpactPlanView } from "./initiative-plugin-views/impact-plan-view.tsx";
import { buildInitiativesView } from "./initiative-plugin-views/initiatives-view.tsx";

type Viewname = "dashboard" | "initiatives" | "impact-plan";

const POLLING_INTERVAL = 30000;
const ROUTES_TO_POLL = [
  APP_ROUTES.oeComments,
  APP_ROUTES.addInitiative,
  APP_ROUTES.editImpactPlan,
  APP_ROUTES.editInitiatives,
  APP_ROUTES.operatingEntities,
] as const;
const RESOURCES_TO_CHECK_FOR_LOCKING = [
  LOCK_RESOURCE_NAMES.oeComments,
  LOCK_RESOURCE_NAMES.oeInitiatives,
  LOCK_RESOURCE_NAMES.oeImpactPlan,
];

export interface OEInitiativesPluginPartialState {
  currentOE?: BffOe & {
    lockDetails?: LockDetails;
  };
  currentOEInitiatives: BffOeInitiatives;
  currentEditInitiative?: EditInitiativesResponse;
  currentOEComments: CommentsByYearAndQuarter;
  showEditFormConfirmationDialog: boolean;
  editFormConfirmationDialogType?: INFormConfirmationDialogType;
  editFormConfirmationNavigatePath?: string;
  previousEditFormConfirmationDialogType?: INFormConfirmationDialogType;
  initiativeFilterData: InitiativeFilterData;
  initiativeSortData: InitiativeSortData;
}

export class OeInitiativesPlugin
  implements
    Plugin<InAppState, OEInitiativesPluginPartialState, "oeId" | "viewname">
{
  route?: Route<"oeId" | "viewname", InAppState>;
  private initiativesView?: View<InAppState, TabsLayoutProps>;
  private app?: App<InAppState>;
  private resourceLockPollingInterval?: NodeJS.Timeout;

  onRegister(app: App<InAppState>): void {
    this.app = app;
    const tabs: {
      name: string;
      viewname: Viewname;
      path: `/operating-entities/:oeId/${Viewname}`;
    }[] = [
      {
        name: "Dashboard",
        viewname: "dashboard",
        path: `/operating-entities/:oeId/dashboard`,
      },
      {
        name: "Initiatives",
        viewname: "initiatives",
        path: `/operating-entities/:oeId/initiatives`,
      },
      {
        name: "Productivity Impact Plan",
        viewname: "impact-plan",
        path: `/operating-entities/:oeId/impact-plan`,
      },
    ];

    this.initiativesView = app.createView({
      name: "Initiatives",
      layout: new TabLayout(tabs, 1, []),
    });

    const view = this.initiativesView;

    buildDashboardView(view, app);
    buildInitiativesView(view, app);
    buildProductivityImpactPlanView(view, app);

    this.setupLockedResourcePolling(app);

    this.route = app.createRoute<"oeId" | "viewname">({
      path: APP_ROUTES.operatingEntities,
      view: this.initiativesView,
    });

    this.route.on("load", async ({ payload }) => {
      const params = payload?.parameters;

      if (!params?.oeId || !this.app) {
        return;
      }

      const [
        {
          aggregatedImpactValues,
          initiatives = [],
          phasesOverview = {},
          enablerOverview = {},
          totalImpactValue = { value: 0, targetYear: "" },
          updateStatusOverview = {
            updateStatus: {},
            completionStatusByEnabler: {},
            updateProgress: 0,
            numberOfInitiatives: 0,
          },
        },
        currentOE,
        currentOEComments,
      ] = await Promise.all([
        fetchOEInitiatives(params.oeId),
        fetchOE(params.oeId),
        fetchOEComments(+params.oeId),
      ]);
      const lockDetails: LockDetails = await this.getLockDetails(
        app,
        params.oeId
      );

      if (!currentOE.id) {
        return;
      }

      this.app.patchAppState({
        currentOEInitiatives: {
          aggregatedImpactValues,
          enablerOverview,
          initiatives,
          phasesOverview,
          totalImpactValue,
          updateStatusOverview,
        },
        currentOE: {
          ...currentOE,
          lockDetails,
        },
        currentOEComments,
        isLoading: false,
      });
    });

    buildImpactPlanFormRoute(app, this.route);
    buildFormRoute(app, this.route);
    buildCommentsFormRoute(app, this.route);
  }

  private setupLockedResourcePolling(app: App<InAppState>) {
    const pollForResourcePolling = () => {
      const currentOE = app.getAppState().currentOE;

      if (!currentOE) {
        return;
      }

      void this.getLockDetails(app, currentOE.id).then((lockDetails) =>
        app.patchAppState({
          currentOE: {
            ...currentOE,
            lockDetails,
          },
        })
      );
    };

    this.app?.on("router:route-loaded", ({ payload }) => {
      if (!payload?.path) {
        return;
      }

      if (
        ROUTES_TO_POLL.includes(payload.path as (typeof ROUTES_TO_POLL)[number])
      ) {
        if (!this.resourceLockPollingInterval) {
          this.resourceLockPollingInterval = setInterval(
            pollForResourcePolling,
            POLLING_INTERVAL
          );
        }
        return;
      }

      if (this.resourceLockPollingInterval) {
        clearInterval(this.resourceLockPollingInterval);
      }
      this.resourceLockPollingInterval = undefined;
    });
  }

  private async getLockDetails(app: App<InAppState>, oeId: string) {
    const resourceLockPlugin =
      app.getPluginByName<ResourceLockPlugin<InAppState>>("ResourceLockPlugin");

    const lockDetails: LockDetails = {};

    for (const object of RESOURCES_TO_CHECK_FOR_LOCKING) {
      const lockDetail = await resourceLockPlugin?.isLocked(
        `${object}-${oeId}`
      );

      if (lockDetail) {
        lockDetails[object] = lockDetail;
      }
    }
    return lockDetails;
  }
}
