import { FileDownloadOutlined, Logout } from "@mui/icons-material";
import type { PimoReactComponent } from "@pimo/pimo-app-builder";
import {
  DefaultOverlay,
  DefaultOverlayEventNames,
  type DefaultOverlayProps,
  type DefaultOverlayEventPayload,
} from "@pimo/pimo-components";
import { OperatingEntity, Program, Profile } from "in-types";
import { STRAPI_URL } from "../../app/env";

export type InOverlayProps = DefaultOverlayProps & {
  operatingEntities?: OperatingEntity[];
  program?: Program;
  reportingDate?: string;
  userProfile?: Profile;
};

export type InOverlayEventNames =
  | DefaultOverlayEventNames
  | "overlay:download-report"
  | "overlay:download-excel";

export type InOverlayEventPayload =
  | Record<string, never>
  | DefaultOverlayEventPayload;

export const InOverlay: PimoReactComponent<
  InOverlayProps,
  InOverlayEventNames,
  InOverlayEventPayload
> = ({ fireEvent, ...props }) => {
  const headerProps: InOverlayProps["header"] = {
    ...props.header,
    entries: [
      ...(props?.userProfile?.role === "admin"
        ? [
            {
              text: "Download Data",
              icon: FileDownloadOutlined,
              onClick: () => window.open(`${STRAPI_URL}/api/export`),
            },
          ]
        : []),
      {
        text: "Logout",
        onClick: () => fireEvent?.("overlay:logout"),

        icon: Logout,
      },
    ],
    logo: { big: "allianz.svg", small: "allianz.svg" },
    username: props.userProfile
      ? `${props.userProfile.name} (${props.userProfile.email})`
      : "",
  };

  return (
    <DefaultOverlay {...props} fireEvent={fireEvent} header={headerProps} />
  );
};
