import dayjs from "dayjs";
import {
  EditInitiativeDates,
  QUANTITATIVE_IMPACT_TYPES,
  type DataItem,
  type EditInitiativesResponse,
  type ImpactByYearAndQuarter,
  type Initiative,
  type InitiativeEnablerFieldType,
  type Quarter,
} from "in-types";

const FIELDS_TO_OMIT = [
  "grossEfficiencyImpactActual",
  "grossEfficiencyImpactPlanned",
  "initiativeEnablers",
  "processMiningEnablementType",
  "processMiningEnablementTypeOther",
  "automationAIPowered",
  "automationGenAIPowered",
  "automationComplexity",
  "automationInvolvedSystem",
  "automationMethodology",
  "automationMethodologyOther",
  "automationSolutionImplementationProvider",
  "automationTechnicalSolution",
  "automationUnderlyingPlatform",
  "relatedKPIDashboardHyperlink",
] satisfies (keyof Initiative)[];

export const convertInitiativeToEditInitiative = (
  initiative?: Initiative
): EditInitiativesResponse | undefined => {
  if (!initiative) {
    return;
  }

  const converted: EditInitiativesResponse = {
    ...convertImpactValuesToMap(initiative),
    initiativeEnablerFields: {
      initiativeEnablers: initiative?.initiativeEnablers ?? [],
      processMiningEnablementType:
        initiative?.processMiningEnablementType ?? [],
      processMiningEnablementTypeOther:
        initiative?.processMiningEnablementTypeOther,
      automationAIPowered: initiative?.automationAIPowered ?? false,
      automationGenAIPowered: initiative?.automationGenAIPowered ?? false,
      automationComplexity: initiative?.automationComplexity,
      automationInvolvedSystem: initiative?.automationInvolvedSystem,
      automationMethodology: initiative?.automationMethodology ?? [],
      automationMethodologyOther: initiative?.automationMethodologyOther ?? "",
      automationSolutionImplementationProvider:
        initiative?.automationSolutionImplementationProvider,
      automationTechnicalSolution: initiative?.automationTechnicalSolution,
      automationUnderlyingPlatform:
        initiative?.automationUnderlyingPlatform ?? [],
    },
    dates: {
      startDate: initiative.startDate ?? null,
      endDate: initiative.endDate ?? null,
    },
  };

  FIELDS_TO_OMIT.forEach((field) => {
    delete converted[field as keyof EditInitiativesResponse];
  });

  return converted;
};

export const convertEditInitiativeToInitiative = (
  editInitiative?: EditInitiativesResponse & {
    grossGrowthImpactPlanned?: DataItem[];
    grossGrowthImpactActual?: DataItem[];
    grossEfficiencyImpactPlanned?: DataItem[];
    grossEfficiencyImpactActual?: DataItem[];
    grossEffectivenessImpactPlanned?: DataItem[];
    grossEffectivenessImpactActual?: DataItem[];
  }
): Initiative | undefined => {
  if (!editInitiative) {
    return;
  }

  if (!editInitiative.function) {
    delete editInitiative.function;
  }

  if (!editInitiative.lineOfBusiness?.includes("Other")) {
    editInitiative.lineOfBusinessOther = "";
  }

  if (!editInitiative.impactType?.includes("Qualitative")) {
    editInitiative.qualitativeImpactType = null;
  }
  if (editInitiative.qualitativeImpactType !== "Other") {
    editInitiative.qualitativeImpactTypeOther = "";
  }

  const initiativeEnablerFields = deleteUnsetInitiativeEnablerFields(
    editInitiative.initiativeEnablerFields
  );

  const {
    // Remove impact objects from initiative.
    /* eslint-disable @typescript-eslint/no-unused-vars */
    grossGrowthImpact,
    grossEfficiencyImpact,
    grossEffectivenessImpact,
    /* eslint-enable @typescript-eslint/no-unused-vars */
    dates,
    ...rest
  } = editInitiative;

  const converted = {
    ...rest,
    startDate: dates?.startDate,
    endDate: dates?.endDate,
    ...initiativeEnablerFields,
  };

  QUANTITATIVE_IMPACT_TYPES.forEach((impactType) => {
    if (editInitiative.quantitativeImpactType?.includes(impactType)) {
      converted[`gross${impactType}ImpactActual`] = convertImpactValuesToArray({
        impactValues: editInitiative[`gross${impactType}Impact`]?.actual,
        dates: editInitiative.dates,
      });
      converted[`gross${impactType}ImpactPlanned`] = convertImpactValuesToArray(
        {
          impactValues: editInitiative[`gross${impactType}Impact`]?.planned,
          dates: editInitiative.dates,
        }
      );
    } else {
      converted[`gross${impactType}ImpactActual`] = [];
      converted[`gross${impactType}ImpactPlanned`] = [];
    }
  });

  return converted as Initiative;
};

export const getEmptyInitiative = () => {
  return {
    uid: "",
    initiativeName: "",
    initiativeDescription: "",
    initiativePhase: null,
    itChangeRequired: false,
    startDate: undefined,
    operatingEntity: undefined,
    implementingBusinessUnit: "",
    function: [],
    subFunction: "",
    lineOfBusiness: [],
    lineOfBusinessOther: "",
    impactValue: undefined,
    impactType: [],
    initiativeOwner: "",
    implementationArm: "",
    implementationArmOther: "",
    quantitativeImpactType: [],
    grossGrowthImpact: { actual: {}, planned: {} },
    grossEfficiencyImpact: { actual: {}, planned: {} },
    grossEffectivenessImpact: { actual: {}, planned: {} },
    initiativeEnablerFields: {
      initiativeEnablers: [],
      processMiningEnablementType: [],
      processMiningEnablementTypeOther: "",
      automationAIPowered: false,
      automationGenAIPowered: false,
      automationComplexity: "",
      automationInvolvedSystem: "",
      automationMethodology: [],
      automationMethodologyOther: "",
      automationSolutionImplementationProvider: "",
      automationTechnicalSolution: "",
      automationUnderlyingPlatform: [],
    },
    relatedKPIDashboardHyperlink: "",
    dates: {
      startDate: null,
      endDate: null,
    },
  } as EditInitiativesResponse;
};

const deleteUnsetInitiativeEnablerFields = (
  initiativeEnablerFields?: InitiativeEnablerFieldType
): InitiativeEnablerFieldType => {
  if (!initiativeEnablerFields) {
    return {
      processMiningEnablementType: [],
      processMiningEnablementTypeOther: "",
      automationAIPowered: false,
      automationGenAIPowered: false,
      automationComplexity: undefined,
      automationInvolvedSystem: "",
      automationMethodology: [],
      automationMethodologyOther: "",
      automationSolutionImplementationProvider: undefined,
      automationTechnicalSolution: "",
      automationUnderlyingPlatform: [],
    };
  }

  const enablers = initiativeEnablerFields.initiativeEnablers;

  if (!enablers?.includes("Process Mining")) {
    initiativeEnablerFields = {
      ...initiativeEnablerFields,
      processMiningEnablementType: [],
      processMiningEnablementTypeOther: "",
    };
  }

  if (!enablers?.includes("Automation")) {
    initiativeEnablerFields = {
      ...initiativeEnablerFields,
      automationAIPowered: false,
      automationGenAIPowered: false,
      automationComplexity: undefined,
      automationInvolvedSystem: "",
      automationMethodology: [],
      automationMethodologyOther: "",
      automationSolutionImplementationProvider: undefined,
      automationTechnicalSolution: "",
      automationUnderlyingPlatform: [],
    };
  }

  if (!initiativeEnablerFields?.automationMethodology?.includes("Other")) {
    initiativeEnablerFields.automationMethodologyOther = "";
  }

  return initiativeEnablerFields;
};

function convertImpactValuesToArray({
  impactValues,
  dates,
}: {
  impactValues?: ImpactByYearAndQuarter;
  dates?: EditInitiativeDates;
}): DataItem[] {
  if (!impactValues || !dates?.startDate || !dates?.endDate) {
    return [];
  }

  const impactArray: DataItem[] = [];
  const startQuarter = dayjs(dates.startDate).quarter();
  const startYear = dayjs(dates.startDate).year();
  const endQuarter = dayjs(dates.endDate).quarter();
  const endYear = dayjs(dates.endDate).year();

  Object.entries(impactValues).forEach(([year, impactByQuarter]) => {
    if (+year < startYear || +year > endYear) {
      return;
    }

    Object.entries(impactByQuarter).forEach(([quarter, impact]) => {
      if (
        (+impact.year === startYear && +quarter.slice(1) < startQuarter) ||
        (+impact.year === endYear && +quarter.slice(1) > endQuarter)
      ) {
        return;
      }

      if (impact.value != null && impact.value !== "") {
        const numberValue = Number(impact.value);

        if (!Number.isNaN(numberValue)) {
          impactArray.push({ ...impact, value: numberValue });
        }
      }
    });
  });

  return impactArray;
}

function convertImpactValuesToMap(
  initiative: Initiative
): EditInitiativesResponse {
  const reduceImpact = (impact: DataItem[]) =>
    impact.reduce<ImpactByYearAndQuarter>((acc, impact) => {
      if (!impact.year || !impact.quarter) {
        return acc;
      }

      if (!acc[impact.year]) {
        acc[impact.year] = {} as Record<Quarter, DataItem>;
      }
      acc[impact.year][impact.quarter] = impact;

      return acc;
    }, {} as ImpactByYearAndQuarter);

  const {
    grossGrowthImpactPlanned,
    grossGrowthImpactActual,
    grossEfficiencyImpactPlanned,
    grossEfficiencyImpactActual,
    grossEffectivenessImpactPlanned,
    grossEffectivenessImpactActual,
    ...rest
  } = initiative;

  return {
    ...rest,
    grossGrowthImpact: {
      planned: reduceImpact(grossGrowthImpactPlanned ?? []),
      actual: reduceImpact(grossGrowthImpactActual ?? []),
    },
    grossEfficiencyImpact: {
      planned: reduceImpact(grossEfficiencyImpactPlanned ?? []),
      actual: reduceImpact(grossEfficiencyImpactActual ?? []),
    },
    grossEffectivenessImpact: {
      planned: reduceImpact(grossEffectivenessImpactPlanned ?? []),
      actual: reduceImpact(grossEffectivenessImpactActual ?? []),
    },
  };
}
