import { QUARTERS } from "in-utils";

export const generateQuarters = (
  startQuarter: string,
  startYear: string,
  numQuarters: number
): string[] => {
  const quarters: string[] = [];
  let currentQuarterIndex = QUARTERS.indexOf(startQuarter);
  let currentYear = parseInt(startYear, 10);

  for (let i = 0; i < numQuarters; i++) {
    quarters.push(`${QUARTERS[currentQuarterIndex]} ${currentYear}`);
    currentQuarterIndex++;
    if (currentQuarterIndex === 4) {
      currentQuarterIndex = 0;
      currentYear++;
    }
  }
  return quarters;
};

/**
 * Converts the quarterly data to yearly. Since the impact values are cumulative, the Q4 value represents the yearly value.
 * Only if a year is not fully included in the dataset, we use the last value.
 */
export const convertToYearlyData = (
  data: (number | null)[],
  initialStartQuarter: string
): (number | null)[] => {
  if (!data || data.length === 0) return [];

  let currentQuarter = +initialStartQuarter.slice(1);
  const yearlyData: (number | null)[] = [];

  data.forEach((value, index) => {
    // use Q4 value for year, or the last index, in case the last year is not fully in the dataset.
    if (currentQuarter === 4 || index === data.length - 1) {
      yearlyData.push(value);
    }

    currentQuarter++;
    if (currentQuarter > 4) {
      currentQuarter = 1;
    }
  });

  return yearlyData;
};
