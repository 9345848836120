import { Box } from "@mui/material";
import { FC } from "react";

import { getDraftIcon } from "../../app/utils";

export const DraftIcon: FC = () => {
  return (
    <Box component="img" src={getDraftIcon()} sx={{ width: 25, height: 25 }} />
  );
};
