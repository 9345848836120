import { Button } from "@mui/material";
import { App, View } from "@pimo/pimo-app-builder";
import {
  StackedBarChartCard,
  type StackedBarChartCardSeries,
  type TabsLayoutProps,
  TitleCard,
  Tooltip,
} from "@pimo/pimo-components";
import { UpdateStatus } from "in-types";
import { generatePath, useNavigate } from "react-router-dom";
import { ImpactPlanTable } from "../../../components/impact-plan/impact-plan-table";
import { InAppState } from "../../app";
import { APP_ROUTES } from "../../constants";
import { LOCK_RESOURCE_NAMES, LOCK_RESOURCE_TEXTS } from "in-utils";
import { ResourceLockPlugin } from "@pimo/resource-lock-plugin";

export function buildProductivityImpactPlanView(
  view: View<InAppState, TabsLayoutProps>,
  app: App<InAppState>
) {
  const viewTitle = view.addComponent({
    component: TitleCard,
    layoutProps: {
      xs: 12,
      viewname: "impact-plan",
    },
  });

  viewTitle.mapState(({ currentOE, userProfile }) => {
    const navigate = useNavigate();
    const lockDetail =
      currentOE?.lockDetails?.[LOCK_RESOURCE_NAMES.oeImpactPlan];
    const isLockedByOtherUser =
      lockDetail?.isLocked &&
      !!lockDetail.lockedBy &&
      !!userProfile?.email &&
      lockDetail.lockedBy !== userProfile.email;

    return {
      title: "Productivity Impact Plan",
      endSlot: (
        <Tooltip
          title={
            isLockedByOtherUser
              ? LOCK_RESOURCE_TEXTS.resourceCurrentlyLocked(
                  "productivity impact plan",
                  lockDetail.lockedBy ?? "Unknown"
                )
              : ""
          }
        >
          <span>
            <Button
              disabled={isLockedByOtherUser}
              variant="outlined"
              onClick={() => {
                const lockResourcePlugin =
                  app.getPluginByName<ResourceLockPlugin<InAppState>>(
                    "ResourceLockPlugin"
                  );

                if (!currentOE) {
                  return;
                }

                void lockResourcePlugin?.lock(
                  `${LOCK_RESOURCE_NAMES.oeImpactPlan}-${currentOE.id}`
                );

                navigate(
                  generatePath(APP_ROUTES.editImpactPlan, {
                    oeId: currentOE?.id ?? "",
                    viewname: "impact-plan",
                  })
                );
              }}
            >
              Edit {isLockedByOtherUser && "🔒"}
            </Button>
          </span>
        </Tooltip>
      ),
    };
  });

  const impactPlanTable = view.addComponent({
    component: ImpactPlanTable,
    layoutProps: {
      xs: 12,
      viewname: "impact-plan",
    },
  });

  impactPlanTable.mapState((appState) => {
    const currentYear = new Date().getFullYear();
    const impactPlans = appState.currentOE?.impactPlans ?? {};

    const statusRow: UpdateStatus[] = [];
    const totalRow: number[] = [];
    const inPlanRow: number[] = [];
    const onTopOfPlanRow: number[] = [];

    for (let year = currentYear; year <= currentYear + 4; year++) {
      statusRow.push(impactPlans[year]?.status);
      totalRow.push(impactPlans[year]?.total);
      inPlanRow.push(impactPlans[year]?.inPDPlan);
      onTopOfPlanRow.push(impactPlans[year]?.onTopOfPDPlan);
    }

    return {
      statusRow,
      totalRow,
      inPlanRow,
      onTopOfPlanRow,
    };
  });

  const impactPlanChart = view.addComponent({
    component: StackedBarChartCard,
    layoutProps: {
      xs: 12,
      viewname: "impact-plan",
    },
  });

  impactPlanChart.mapState((appState) => {
    const currentYear = new Date().getFullYear();
    const impactPlans = appState.currentOE?.impactPlans;

    const categories = [];
    const series: StackedBarChartCardSeries = [
      {
        name: "In PD plan",
        value: [],
      },
      {
        name: "On top of PD plan",
        value: [],
      },
    ];

    for (let year = currentYear; year <= currentYear + 4; year++) {
      if (!impactPlans) {
        break;
      }

      categories.push(String(year));
      series[0].value.push(impactPlans[year]?.inPDPlan);
      series[1].value.push(impactPlans[year]?.onTopOfPDPlan);
    }

    return {
      cardProps: {
        sx: {
          flex: 1,
          borderRadius: "8px",
        },
      },
      title: "Productivity Impact Plan",
      titleMargin: "8px",
      showYAxisLabels: false,
      showXAxisLabels: true,
      showGridLines: true,
      showTotals: true,
      fullWidth: true,
      dataLabelFormatter: (val) => {
        if (Array.isArray(val)) {
          return val.join(",");
        }
        if (Number.isNaN(val)) {
          return "";
        }
        return `${val} Mn`;
      },
      orientation: "vertical",
      colors: ["#003781", "#94CADC"],
      categories,
      groupedSeries: [
        {
          series,
        },
      ],
    };
  });
}
