import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { Card } from "@pimo/pimo-components";
import dayjs from "dayjs";
import { UpdateStatus } from "in-types";

import { getStatusIcon } from "../../app/utils";

export type ImpactPlanTableProps = {
  statusRow: UpdateStatus[];
  totalRow: number[];
  inPlanRow: number[];
  onTopOfPlanRow: number[];
};

export function ImpactPlanTable({
  statusRow,
  totalRow,
  inPlanRow,
  onTopOfPlanRow,
}: ImpactPlanTableProps) {
  const year = dayjs().year();

  return (
    <Card title="PD Impact Figures">
      <TableContainer sx={{ borderRadius: "12px" }} component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>{year}</TableCell>
              <TableCell>{year + 1}</TableCell>
              <TableCell>{year + 2}</TableCell>
              <TableCell>{year + 3}</TableCell>
              <TableCell>{year + 4}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>Status</TableCell>
              {statusRow.map((status, index) => (
                <TableCell key={`statusRow-${year + index}`}>
                  <Box
                    component={"img"}
                    src={getStatusIcon(status ?? "overdue")}
                    sx={{ width: 25, height: 25 }}
                  ></Box>
                </TableCell>
              ))}
            </TableRow>
            {getTableRow("Total", totalRow, year)}
            {getTableRow("Thereof in PD Plan", inPlanRow, year)}
            {getTableRow("On Top of PD Plan", onTopOfPlanRow, year)}
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
}

function getTableRow(label: string, row: number[], year: number) {
  return (
    <TableRow>
      <TableCell>{label}</TableCell>
      {row.map((total, index) => (
        <TableCell key={`totalRow-${year + index}`}>
          {!!total && `${total} Mn`}
        </TableCell>
      ))}
    </TableRow>
  );
}
