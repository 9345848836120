import { InputLabel, TextField, Typography } from "@mui/material";
import {
  AdditionalFormFieldProps,
  BaseFormFieldDefinition,
} from "@pimo/pimo-components/src/lib/pimo-components/pimo-form/form-field";
import { EditInitiativesResponse } from "in-types";
import { useWatch } from "react-hook-form";

// eslint-disable-next-line react-refresh/only-export-components
export const INITIATIVE_LINE_OF_BUSINESS_OTHER_FIELD =
  "initiative_line_of_business_other_field" as const;

export type InitiativeLineOfBusinessOtherFieldDefinition =
  BaseFormFieldDefinition<typeof INITIATIVE_LINE_OF_BUSINESS_OTHER_FIELD> & {
    type: typeof INITIATIVE_LINE_OF_BUSINESS_OTHER_FIELD;
    name: "lineOfBusinessOther";
  };

const LABEL_STYLE = {
  color: "#000000DE",
  lineHeight: 1.5,
  fontWeight: 500,
  fontSize: "14px",
};

export function InitiativeLineOfBusinessOtherField({
  name,
  register,
  errorMessage,
}: InitiativeLineOfBusinessOtherFieldDefinition & AdditionalFormFieldProps) {
  const { lineOfBusiness, lineOfBusinessOther } =
    useWatch<EditInitiativesResponse>();

  if (!lineOfBusiness?.includes("Other")) {
    return <></>;
  }

  return (
    <>
      <InputLabel>
        <Typography sx={LABEL_STYLE}>Line of Business Other*</Typography>
      </InputLabel>
      <TextField
        fullWidth
        type="text"
        {...register(name)}
        value={lineOfBusinessOther ?? ""}
        helperText={errorMessage}
        error={!!errorMessage}
      />
    </>
  );
}
