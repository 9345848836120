import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import {
  AdditionalFormFieldProps,
  BaseFormFieldDefinition,
} from "@pimo/pimo-components/src/lib/pimo-components/pimo-form/form-field";
import { EditInitiativesResponse, LINES_OF_BUSINESS } from "in-types";
import { useWatch } from "react-hook-form";

// eslint-disable-next-line react-refresh/only-export-components
export const INITIATIVE_LINE_OF_BUSINESS_FIELD =
  "initiative_line_of_business_field" as const;

export type InitiativeLineOfBusinessFieldDefinition = BaseFormFieldDefinition<
  typeof INITIATIVE_LINE_OF_BUSINESS_FIELD
> & {
  type: typeof INITIATIVE_LINE_OF_BUSINESS_FIELD;
  name: "lineOfBusiness";
};

export function InitiativeLineOfBusinessField({
  name,
  register,
  errorMessage,
}: InitiativeLineOfBusinessFieldDefinition & AdditionalFormFieldProps) {
  const lineOfBusiness = useWatch<EditInitiativesResponse>({
    name,
  });

  return (
    <>
      <InputLabel>
        <Typography
          sx={{
            color: "#000000DE",
            lineHeight: 1.5,
            fontWeight: 500,
            fontSize: "14px",
          }}
        >
          Line of Business*
        </Typography>
      </InputLabel>
      <FormControl error={!!errorMessage} fullWidth>
        <Select
          multiple
          fullWidth
          {...register(name)}
          value={lineOfBusiness ?? []}
        >
          {LINES_OF_BUSINESS.map((lob) => (
            <MenuItem key={lob} value={lob}>
              {lob}
            </MenuItem>
          ))}
        </Select>
        {errorMessage && <FormHelperText>{errorMessage}</FormHelperText>}
      </FormControl>
    </>
  );
}
