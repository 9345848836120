import type { UpdateStatus } from "in-types";

export const STATUS_MAPPING = {
  completed: "mood-smile.svg",
  overdue: "mood-sad.svg",
  pending: "mood-neutral.svg",
} as const satisfies Record<UpdateStatus, string>;

export function getStatusIcon<T extends UpdateStatus>(
  status: T
): (typeof STATUS_MAPPING)[T] {
  return STATUS_MAPPING[status];
}
