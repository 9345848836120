import { Cache } from "@pimo/pimo-app-builder";
import {
  BffOe,
  BffOeInitiatives,
  BffOes,
  CommentsByYearAndQuarter,
  FilterData,
  Initiative,
  Program,
} from "in-types";

import { STRAPI_URL } from "../env";

const cache = new Cache();

export async function fetchProgram(): Promise<Program> {
  try {
    const response = await cache.fetch(`${STRAPI_URL}/api/bff-program`, {
      credentials: "include",
    });

    if (response.status >= 400) {
      throw new Error(`Fetch failed with status ${response.status}.`);
    }

    return (await response.json()) as Program;
  } catch {
    return {} as Program;
  }
}

export async function updateReportingDate(reportingDate: string) {
  try {
    const response = await cache.fetch(`${STRAPI_URL}/api/bff-program`, {
      method: "PUT",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: { reportingDate },
      }),
    });
    if (response.status >= 400) {
      throw new Error(`Fetch failed with status ${response.status}.`);
    }
    const data = (await response.json()) as Program;

    return data;
  } catch {
    return;
  }
}

export async function fetchOEs(
  payload?: FilterData,
  force?: boolean
): Promise<BffOes> {
  const params = new URLSearchParams();
  const appendArrayParams = (key: string, values?: string[]) => {
    if (values?.length) {
      params.append(key, encodeURIComponent(values.join(",")));
    }
  };

  try {
    appendArrayParams("operatingEntity", payload?.oeNameFilter);
    appendArrayParams("updated", payload?.updatedFilter);
    appendArrayParams("impactLead", payload?.impactLeadFilter);
    appendArrayParams("OERegion", payload?.regionFilter);

    if (payload?.searchQueryFilter) {
      params.append("search", payload.searchQueryFilter);
    }

    const url = new URL(`${STRAPI_URL}/api/bff-oe`);

    url.search = params.toString();

    const response = await cache.fetch(
      url.toString(),
      { credentials: "include" },
      force
    );

    if (response.status >= 400) {
      throw new Error(`Fetch failed with status ${response.status}.`);
    }

    return (await response.json()) as BffOes;
  } catch (error) {
    return {} as BffOes;
  }
}

export async function fetchOE(id: string): Promise<BffOe> {
  try {
    const response = await fetch(`${STRAPI_URL}/api/bff-oe/${id}`, {
      credentials: "include",
    });

    if (response.status >= 400) {
      throw new Error(`Fetch failed with status ${response.status}.`);
    }

    const data = (await response.json()) as Promise<BffOe>;

    return data;
  } catch {
    return {} as BffOe;
  }
}

export async function fetchOEInitiatives(
  oeId: string
): Promise<BffOeInitiatives> {
  try {
    const url = new URL(`${STRAPI_URL}/api/bff-oe-initiatives/${oeId}`);

    const response = await fetch(url.toString(), {
      credentials: "include",
    });

    if (!response.ok) {
      throw new Error(`Fetch failed with status ${response.status}.`);
    }
    const data: BffOeInitiatives = (await response.json()) as BffOeInitiatives;
    return data;
  } catch {
    return {} as BffOeInitiatives;
  }
}

export async function saveInitiative(
  id: number | string | undefined,
  initiative: Initiative
): Promise<Initiative | undefined> {
  try {
    const response = await fetch(
      `${STRAPI_URL}/api/bff-oe-initiatives/${String(id)}`,
      {
        body: JSON.stringify(initiative),
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        method: "PUT",
      }
    );

    if (!response.ok) {
      throw new Error(`saveInitiative failed with status ${response.status}.`);
    }

    return (await response.json()) as Promise<Initiative>;
  } catch {
    return undefined;
  }
}

export async function fetchOEComments(
  oeId: number
): Promise<CommentsByYearAndQuarter> {
  try {
    const response = await fetch(
      `${STRAPI_URL}/api/bff-oe-comment/${String(oeId)}`,
      {
        credentials: "include",
      }
    );

    if (!response.ok) {
      throw new Error(
        `Fetching comments for oe failed with status ${response.status}.`
      );
    }

    return (await response.json()) as CommentsByYearAndQuarter;
  } catch {
    return {} as CommentsByYearAndQuarter;
  }
}

export async function saveImpactPlansForOE(oe: BffOe) {
  try {
    const response = await fetch(
      `${STRAPI_URL}/api/bff-oe-impact-plans/${oe.id}`,
      {
        method: "PUT",
        credentials: "include",
        body: JSON.stringify(oe.impactPlans),
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (!response.ok) {
      throw new Error(
        `saveImpactPlansForOE failed with status ${response.status}`
      );
    }

    return (await response.json()) as BffOe;
  } catch {
    return undefined;
  }
}

export async function saveCommentsForEO(
  oeId: number,
  comments: CommentsByYearAndQuarter
): Promise<undefined> {
  try {
    const response = await fetch(`${STRAPI_URL}/api/bff-oe-comment/${oeId}`, {
      body: JSON.stringify(comments),
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      method: "PUT",
    });

    if (!response.ok) {
      throw new Error(
        `saveCommentsForEO failed with status ${response.status}.`
      );
    }
  } catch {
    return undefined;
  }
}

export async function addNewInitiative(
  initiative: Initiative
): Promise<Initiative | undefined> {
  try {
    const response = await fetch(`${STRAPI_URL}/api/bff-oe-initiatives`, {
      body: JSON.stringify(initiative),
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      method: "POST",
    });

    if (!response.ok) {
      throw new Error(`addInitiative failed with status ${response.status}.`);
    }
    return (await response.json()) as Initiative;
  } catch {
    return undefined;
  }
}

export async function markInitiativeDeleted(
  initiativeId: number
): Promise<void> {
  try {
    const response = await fetch(
      `${STRAPI_URL}/api/bff-oe-initiatives/${initiativeId}`,
      {
        method: "DELETE",
        credentials: "include",
      }
    );

    if (!response.ok) {
      throw new Error(
        `markInitiativeDeleted failed with status ${response.status}`
      );
    }
  } catch {
    return undefined;
  }
}
