import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  TextField,
} from "@mui/material";
import {
  AdditionalFormFieldProps,
  BaseFormFieldDefinition,
} from "@pimo/pimo-components/src/lib/pimo-components/pimo-form/form-field";
import { ImpactPlan, ImpactPlanByYear } from "in-types";
import { useEffect, useRef } from "react";
import { useFormContext, useWatch } from "react-hook-form";

// eslint-disable-next-line react-refresh/only-export-components
export const IMPACT_PLAN_FIELD_TYPE = "impact_plan_field" as const;

export type ImpactPlanFieldDefinition = BaseFormFieldDefinition<
  typeof IMPACT_PLAN_FIELD_TYPE
> & {
  type: typeof IMPACT_PLAN_FIELD_TYPE;
  formData: { impactPlans: ImpactPlanByYear };
};

export function ImpactPlanField({
  formData,
}: ImpactPlanFieldDefinition & AdditionalFormFieldProps) {
  const { setValue } = useFormContext();
  const ref = useRef<HTMLInputElement>();
  const currentYear = new Date().getFullYear();
  const impactPlans = (useWatch({ name: "impactPlans" }) ??
    formData.impactPlans) as ImpactPlanByYear;

  const onWheel = (
    event: React.WheelEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => event.currentTarget.blur();

  const onFieldChange = (
    year: number,
    impactPlan: ImpactPlan,
    key: keyof ImpactPlan,
    changed: string
  ) => {
    void setValue(
      "impactPlans",
      {
        ...impactPlans,
        [year]: {
          ...impactPlan,
          [key]: changed,
        },
      },
      { shouldDirty: true, shouldTouch: true, shouldValidate: true }
    );
  };

  const yearsToDisplay = [];

  for (let year = currentYear; year <= currentYear + 4; year++) {
    yearsToDisplay.push(year);
  }

  useEffect(() => {
    if (ref.current) {
      ref.current.focus();
    }
  }, []);

  return (
    <Grid container direction={"column"} spacing={2}>
      {yearsToDisplay.map((year) => {
        const impactPlan = impactPlans?.[year] ?? formData.impactPlans?.[year];

        return (
          <Grid item key={`pd-plan-${year}`}>
            <Accordion defaultExpanded={year === currentYear}>
              <AccordionSummary>{year}</AccordionSummary>
              <AccordionDetails>
                <Grid container direction="column" gap={2}>
                  <TextField
                    type="number"
                    fullWidth
                    label="Total"
                    value={impactPlan?.total ?? ""}
                    // Prevent changing value when scrolling through the form
                    InputProps={{
                      inputProps: {
                        onWheel,
                      },
                    }}
                    onChange={(e) =>
                      onFieldChange(year, impactPlan, "total", e.target.value)
                    }
                    inputRef={(input: HTMLInputElement) => {
                      if (input && year === currentYear) {
                        ref.current = input;
                      }
                    }}
                  />
                  <TextField
                    type="number"
                    fullWidth
                    label="In PD plan"
                    value={impactPlan?.inPDPlan ?? ""}
                    // Prevent changing value when scrolling through the form
                    InputProps={{
                      inputProps: {
                        onWheel,
                      },
                    }}
                    onChange={(e) =>
                      onFieldChange(
                        year,
                        impactPlan,
                        "inPDPlan",
                        e.target.value
                      )
                    }
                  />
                  <TextField
                    type="number"
                    fullWidth
                    label="On top of PD plan"
                    value={impactPlan?.onTopOfPDPlan ?? ""}
                    // Prevent changing value when scrolling through the form
                    InputProps={{
                      inputProps: {
                        onWheel,
                      },
                    }}
                    onChange={(e) =>
                      onFieldChange(
                        year,
                        impactPlan,
                        "onTopOfPDPlan",
                        e.target.value
                      )
                    }
                  />
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>
        );
      })}
    </Grid>
  );
}
