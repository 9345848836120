import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  TextField,
} from "@mui/material";
import {
  AdditionalFormFieldProps,
  BaseFormFieldDefinition,
} from "@pimo/pimo-components/src/lib/pimo-components/pimo-form/form-field";
import dayjs from "dayjs";
import quarterofyear from "dayjs/plugin/quarterOfYear";
import { CommentsByYearAndQuarter, Quarter } from "in-types";
import { getQuarterAndYear } from "in-utils";
import { useWatch } from "react-hook-form";

dayjs.extend(quarterofyear);

// eslint-disable-next-line react-refresh/only-export-components
export const OE_COMMENTS_TABLE_TYPE = "oe_comments_table" as const;

export type OeCommentsTableDefinition = BaseFormFieldDefinition<
  typeof OE_COMMENTS_TABLE_TYPE
> & {
  type: typeof OE_COMMENTS_TABLE_TYPE;
  formData: {
    comments: CommentsByYearAndQuarter;
  };
};

export function OECommentsTable({
  name,
  register,
  formData,
}: OeCommentsTableDefinition & AdditionalFormFieldProps) {
  const [currentQuarter, currentYear] = getQuarterAndYear(new Date());
  const comments = formData.comments;

  // Force fields to update on change
  useWatch({ name: "comments" });
  const { onChange: onGridChange } = register(name);

  if (!comments[currentYear]?.[currentQuarter]) {
    if (!comments[currentYear]) {
      comments[currentYear] = {};
    }

    comments[currentYear][currentQuarter] = {
      content: "",
      quarter: currentQuarter,
      year: currentYear,
    };
  }

  return (
    <Grid container item spacing={2}>
      {Object.entries(comments)
        .sort(([a], [b]) => Number(b) - Number(a))
        .map(([year, commentsByQuarter]) => {
          return (
            <Grid item xs={12} key={`comments-${year}`}>
              <Accordion defaultExpanded={year === currentYear}>
                <AccordionSummary>{year}</AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={2} item xs={12}>
                    {Object.entries(commentsByQuarter).map(
                      ([quarter, comment]) => {
                        const canEdit =
                          quarter === currentQuarter && year === currentYear;
                        return (
                          <Grid item xs={12} key={`comment-${year}-${quarter}`}>
                            <TextField
                              type="text"
                              multiline
                              minRows="4"
                              maxRows="15"
                              fullWidth
                              disabled={!canEdit}
                              label={quarter}
                              onChange={(event) => {
                                const value = event.target.value;
                                const comments = formData.comments;
                                const yearNumber = Number(year);

                                if (!comments[yearNumber]) {
                                  comments[yearNumber] = {
                                    [quarter]: {},
                                  };
                                }

                                comments[yearNumber][quarter as Quarter] = {
                                  content: value,
                                  quarter: quarter as Quarter,
                                  year,
                                };

                                void onGridChange({
                                  target: {
                                    name: "comments",
                                    value: comments,
                                  },
                                });
                              }}
                              value={comment.content}
                            />
                          </Grid>
                        );
                      }
                    )}
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>
          );
        })}
    </Grid>
  );
}
