import { DropdownFilter, CheckBoxFilter } from "@pimo/pimo-components";
import { Close } from "@mui/icons-material";
import { FilterAlt } from "@mui/icons-material";
import { Box, Divider, FormControl, Typography, useTheme } from "@mui/material";
import { FC } from "react";
import { FilterData, FilterOptions } from "in-types";

export interface OEOverviewFilterDialogProps {
  onClick?: () => void;
  handleChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    trigger: string
  ) => void;
  filterData: FilterData;
  filterOptions: FilterOptions;
}

export const OEOverviewFilterDialog: FC<OEOverviewFilterDialogProps> = ({
  onClick,
  handleChange,
  filterData,
  filterOptions,
}) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        p: 1,
        gap: 0,
        width: 300,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "start",
            width: "100%",
            pr: 1,
          }}
        >
          <FilterAlt
            sx={{ color: theme.palette.primary.main, height: "35px" }}
          />
          <Typography sx={{ fontWeight: 500 }}>Filter</Typography>
        </Box>
        <Close onClick={onClick}></Close>
      </Box>
      <Divider
        sx={{
          bgcolor: theme.palette.secondary.main,
          height: "1px",
          borderWidth: 0,
        }}
      />
      <FormControl
        component="fieldset"
        sx={{ display: "flex", flexDirection: "column", gap: 1 }}
      >
        <CheckBoxFilter
          sectionTitle="Updated / Not Updated"
          options={[
            { label: "Updated", value: "updated" },
            { label: "Not Updated", value: "notUpdated" },
          ]}
          triggerIdentifier={"updatedFilter"}
          handleChange={handleChange}
          currentValues={filterData.updatedFilter}
        />
        <Divider
          sx={{
            bgcolor: theme.palette.secondary.main,
            height: "1px",
            borderWidth: 0,
          }}
        />
        <DropdownFilter
          options={filterOptions.oeName ?? []}
          value={filterData.oeNameFilter}
          label="OE"
          handleChange={handleChange}
          triggerIdentifier={"oeNameFilter"}
        />
        <Divider
          sx={{
            bgcolor: theme.palette.secondary.main,
            height: "1px",
            borderWidth: 0,
          }}
        />
        <DropdownFilter
          options={filterOptions.oeRegion ?? []}
          value={filterData.regionFilter}
          label="Region"
          handleChange={handleChange}
          triggerIdentifier={"regionFilter"}
        />
        <Divider
          sx={{
            bgcolor: theme.palette.secondary.main,
            height: "1px",
            borderWidth: 0,
          }}
        />
        <DropdownFilter
          options={filterOptions.impactLead ?? []}
          value={filterData.impactLeadFilter}
          label="Impact Lead"
          handleChange={handleChange}
          triggerIdentifier={"impactLeadFilter"}
        />
      </FormControl>
    </Box>
  );
};
