import { FilterOptions, OeOverview } from "in-types";

export const getFilterOptions = (
  groupOverview: OeOverview[],
  fields: (keyof FilterOptions)[]
): FilterOptions => {
  const filterOptions: FilterOptions = {};

  fields.forEach((field) => {
    filterOptions[field] = [
      ...new Set(
        groupOverview
          .map((item) => item[field])
          .filter((value) => value !== null && value !== undefined)
          .map((value) => String(value))
      ),
    ];
  });

  return filterOptions;
};
