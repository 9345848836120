import { Box, Typography } from "@mui/material";
import { Markdown } from "@pimo/pimo-components";

export type INFormFieldInfoSlotProps = {
  img?: string;
  text: string;
  maxWidth?: string | number;
};

export const INFormFieldInfoSlot: React.FC<INFormFieldInfoSlotProps> = ({
  img,
  text,
  maxWidth,
}) => {
  return (
    <Box
      sx={{
        borderRadius: 6,
        boxShadow: "none",
        display: "flex",
        flexDirection: "column",
        gap: 2,
        maxWidth: maxWidth ?? 500,
        p: 2,
        maxHeight: 700,
        overflowY: "auto",
      }}
    >
      <Typography component="span">
        <Markdown>{text}</Markdown>
      </Typography>
      {img && <img src={img} alt={text} />}
    </Box>
  );
};
