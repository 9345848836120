import { Button, Typography, useTheme } from "@mui/material";
import type { FC } from "react";
import { Link } from "react-router-dom";

type AddInitiativeButtonProps = {
  label: string;
  route: string;
};

export const AddInitiativeButton: FC<AddInitiativeButtonProps> = ({
  label,
  route,
}) => {
  const theme = useTheme();

  return (
    <Link style={{ textDecoration: "none" }} to={route}>
      <Button
        variant="contained"
        sx={{
          width: "100%",
          background: "#007AB3",
          borderCollapse: theme.palette.primary.dark,
          borderRadius: "6px",
        }}
      >
        <Typography
          sx={{
            fontWeight: "600",
            textAlign: "center",
            padding: "4px 0",
            textTransform: "none",
            color: "white",
          }}
        >
          {label}
        </Typography>
      </Button>
    </Link>
  );
};
