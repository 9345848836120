import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import {
  AdditionalFormFieldProps,
  BaseFormFieldDefinition,
} from "@pimo/pimo-components/src/lib/pimo-components/pimo-form/form-field";
import {
  AUTOMATION_COMPLEXITY_TYPES,
  AUTOMATION_METHODOLOGY_TYPE,
  AUTOMATION_SOLUTION_IMPLEMENTATION_PROVIDER_TYPE,
  AUTOMATION_UNDERLYING_PLATFORM_TYPES,
  EditInitiativesResponse,
  INITIATIVE_ENABLER_TYPES,
  InitiativeEnablerFieldType,
  PROCESS_MINING_ENABLEMENT_TYPE,
} from "in-types";
import {
  FieldError,
  FieldValues,
  get,
  useFormContext,
  UseFormRegister,
  useWatch,
} from "react-hook-form";

// eslint-disable-next-line react-refresh/only-export-components
export const INITIATIVE_ENABLER_SUB_FIELD_TYPE =
  "initiative_enabler_sub_field" as const;

export type InitiativeEnablerSubFieldDefinition = BaseFormFieldDefinition<
  typeof INITIATIVE_ENABLER_SUB_FIELD_TYPE
> & {
  type: typeof INITIATIVE_ENABLER_SUB_FIELD_TYPE;
  subType: string;
  name: `initiativeEnablerFields.${keyof InitiativeEnablerFieldType}`;
};

const labelStyle = {
  color: "#000000DE",
  lineHeight: 1.5,
  fontWeight: 500,
  fontSize: "14px",
};

export function InitiativeEnablerSubField({
  register,
  name,
  label,
  subType,
}: InitiativeEnablerSubFieldDefinition & AdditionalFormFieldProps) {
  const value = useWatch<EditInitiativesResponse>({
    name,
  });
  const {
    formState: { errors },
  } = useFormContext();
  const errorMessage = (get(errors, name) as FieldError)?.message;

  switch (subType) {
    case "checkbox":
      return renderCheckbox(
        name,
        label,
        value as boolean,
        register,
        errorMessage
      );
    case "select":
      return renderSelect(name, value as string, register, errorMessage);
    case "multiSelect":
      return renderMultiSelect(name, value as string, register, errorMessage);
    case "text":
      return renderTextField(name, value as string, register, errorMessage);
    default:
      return null;
  }
}

function renderCheckbox(
  name: string,
  label: string | undefined,
  value: boolean,
  register: UseFormRegister<FieldValues>,
  errorMessage: string | undefined
) {
  return (
    <FormControl error={!!errorMessage} fullWidth>
      <FormControlLabel
        label={label ?? <Typography sx={labelStyle}>{label}</Typography>}
        control={<Checkbox {...register(name)} checked={value ?? false} />}
      />
      {errorMessage && <FormHelperText>{errorMessage}</FormHelperText>}
    </FormControl>
  );
}

function renderSelect(
  name: string,
  value: string,
  register: UseFormRegister<FieldValues>,
  errorMessage: string | undefined
) {
  return (
    <FormControl error={!!errorMessage} fullWidth>
      <Select fullWidth {...register(name)} value={value ?? ""}>
        {getSelectOptions(name)}
      </Select>
      {errorMessage && <FormHelperText>{errorMessage}</FormHelperText>}
    </FormControl>
  );
}

function renderMultiSelect(
  name: string,
  value: string,
  register: UseFormRegister<FieldValues>,
  errorMessage: string | undefined
) {
  return (
    <FormControl error={!!errorMessage} fullWidth>
      <Select {...register(name)} fullWidth multiple value={value ?? []}>
        {getSelectOptions(name)}
      </Select>
      {errorMessage && <FormHelperText>{errorMessage}</FormHelperText>}
    </FormControl>
  );
}

function renderTextField(
  name: string,
  value: string,
  register: UseFormRegister<FieldValues>,
  errorMessage: string | undefined
) {
  return (
    <TextField
      {...register(name)}
      fullWidth
      type="text"
      value={value ?? ""}
      helperText={errorMessage}
      error={!!errorMessage}
    />
  );
}

function getSelectOptions(name: string) {
  const mapOptions = (types: readonly string[]) =>
    types.map((type) => (
      <MenuItem key={type} value={type}>
        {type}
      </MenuItem>
    ));
  name = name.includes(".") ? name.split(".")[1] : name;

  const selectOptions = {
    initiativeEnablers: mapOptions(
      INITIATIVE_ENABLER_TYPES.filter((type) => type !== "Total")
    ),
    automationUnderlyingPlatform: mapOptions(
      AUTOMATION_UNDERLYING_PLATFORM_TYPES
    ),
    automationComplexity: mapOptions(AUTOMATION_COMPLEXITY_TYPES),
    automationSolutionImplementationProvider: mapOptions(
      AUTOMATION_SOLUTION_IMPLEMENTATION_PROVIDER_TYPE
    ),
    automationMethodology: mapOptions(AUTOMATION_METHODOLOGY_TYPE),
    processMiningEnablementType: mapOptions(PROCESS_MINING_ENABLEMENT_TYPE),
  };

  return selectOptions[
    name as keyof Omit<
      InitiativeEnablerFieldType,
      | "automationAIPowered"
      | "automationGenAIPowered"
      | "automationInvolvedSystem"
      | "automationTechnicalSolution"
      | "automationMethodologyOther"
      | "processMiningEnablementTypeOther"
    >
  ];
}
