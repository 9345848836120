var INITIATIVE_PHASES = [
    "Idea / Backlog",
    "Planned",
    "In Progress",
    "Done",
];
var FUNCTION_TYPES = [
    "Finance",
    "HR",
    "Audit",
    "L&C",
    "Other Central Function",
    "Operations Support",
    "Real Estate Management",
    "Organizational Management",
    "IT",
    "Provider and Network Management",
    "Technical Cost Center",
    "Market Management",
    "Product Provider",
    "Underwriting & Policy Management",
    "Sales & Distribution",
    "Commissions",
    "Claims",
    "ALAE",
];
var LINES_OF_BUSINESS = [
    "P&C Motor",
    "P&C Non-Motor",
    "Life",
    "Health",
    "Commercial",
    "Other",
];
var IMPACT_TYPES = ["Qualitative", "Quantitative"];
var QUANTITATIVE_IMPACT_TYPES = [
    "Efficiency",
    "Effectiveness",
    "Growth",
];
var QUALITATIVE_IMPACT_TYPES = [
    "Customer Sat. (VOC/OSAT)",
    "Employee Engagement (AES)",
    "Compliance",
    "Other",
];
var QUALITATIVE_IMPACT_TYPE_TO_ACTUAL_IMPACT_VALUE = {
    Efficiency: "grossEfficiencyImpactActual",
    Effectiveness: "grossEffectivenessImpactActual",
    Growth: "grossGrowthImpactActual",
};
var QUALITATIVE_IMPACT_TYPE_TO_PLANNED_IMPACT_VALUE = {
    Efficiency: "grossEfficiencyImpactPlanned",
    Effectiveness: "grossEffectivenessImpactPlanned",
    Growth: "grossGrowthImpactPlanned",
};
var IMPLEMENTATION_ARM_TYPES = [
    "ACM",
    "Beat the Best",
    "ITMP/BMP",
    "Rapid Productivity",
    "Other",
];
var INITIATIVE_ENABLER_TYPES = [
    "Process Mining",
    "Automation",
    "Total",
];
var AUTOMATION_UNDERLYING_PLATFORM_TYPES = [
    "Group Solution",
    "Local Solution",
    "Legacy System (to be decommissioned)",
    "N/A",
];
var AUTOMATION_COMPLEXITY_TYPES = ["Low", "Medium", "High"];
var AUTOMATION_SOLUTION_IMPLEMENTATION_PROVIDER_TYPE = [
    "OE Internal",
    "AZ Services",
    "AZ Technology",
    "Third Party",
];
var AUTOMATION_METHODOLOGY_TYPE = [
    "RPA - Robotics Process Automation",
    "Workflow Automation / Rule based decision",
    "Chatbot",
    "Voicebot",
    "Intelligent Document Processing / Intelligent Document Management",
    "Agent Assist",
    "Other",
];
var PROCESS_MINING_ENABLEMENT_TYPE = [
    "Identification",
    "Monitoring",
    "Direct (e.g. Action Flows)",
    "Other",
];

export { AUTOMATION_COMPLEXITY_TYPES, AUTOMATION_METHODOLOGY_TYPE, AUTOMATION_SOLUTION_IMPLEMENTATION_PROVIDER_TYPE, AUTOMATION_UNDERLYING_PLATFORM_TYPES, FUNCTION_TYPES, IMPACT_TYPES, IMPLEMENTATION_ARM_TYPES, INITIATIVE_ENABLER_TYPES, INITIATIVE_PHASES, LINES_OF_BUSINESS, PROCESS_MINING_ENABLEMENT_TYPE, QUALITATIVE_IMPACT_TYPES, QUALITATIVE_IMPACT_TYPE_TO_ACTUAL_IMPACT_VALUE, QUALITATIVE_IMPACT_TYPE_TO_PLANNED_IMPACT_VALUE, QUANTITATIVE_IMPACT_TYPES };
