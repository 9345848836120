import { Box, CardProps, Typography } from "@mui/material";
import { PimoReactComponent } from "@pimo/pimo-app-builder";
import type { ApexOptions } from "apexcharts";
import ReactApexChart from "react-apexcharts";

import { Card } from "../../molecules";

export type StackedBarChartCardSeries = {
  name: string;
  value: number[];
}[];

export type StackedBarChartCardProps = {
  title?: string;
  categories?: string[];
  colors: string[];
  groupedSeries: {
    background?: string;
    series: StackedBarChartCardSeries;
    categories?: string[];
  }[];
  cardProps?: CardProps;
  labelsColor?: string[];
  orientation?: "horizontal" | "vertical";
  showLegend?: boolean;
  showYAxisLabels?: boolean;
  showXAxisLabels?: boolean;
  showGridLines?: boolean;
  showTotals?: boolean;
  fullWidth?: boolean;
  dataLabelFormatter?: (
    val: string | number | number[],
    opts?: unknown
  ) => string | number;
};

export const StackedBarChartCard: PimoReactComponent<
  StackedBarChartCardProps
> = ({
  groupedSeries,
  categories,
  title,
  colors,
  cardProps,
  labelsColor,
  dataLabelFormatter,
  orientation = "horizontal",
  showTotals = false,
  showLegend = false,
  showYAxisLabels = true,
  showXAxisLabels = false,
  showGridLines = false,
  fullWidth = false,
}) => {
  const options: ApexOptions = {
    chart: {
      type: "bar",
      background: "none",
      stacked: true,
      toolbar: {
        show: false,
      },
      animations: {
        enabled: false,
      },
    },
    colors: colors,
    grid: {
      show: showGridLines,
    },
    stroke: {
      width: 0.5,
      colors: colors,
    },
    dataLabels: {
      enabled: true,
      style: { colors: labelsColor ? labelsColor : ["#000"], fontWeight: 400 },
    },
    legend: { show: showLegend },
    plotOptions: {
      bar: {
        horizontal: orientation === "horizontal",
        barHeight: 20,
        dataLabels: {
          total: {
            enabled: showTotals,
          },
        },
      },
    },
    xaxis: {
      categories: categories,
      axisBorder: { show: false },
      axisTicks: { show: false },
      labels: {
        show: showXAxisLabels,
        style: {
          colors: "#000",
          fontWeight: 400,
        },
      },
    },
    yaxis: {
      labels: {
        show: showYAxisLabels,
        maxWidth: 250,
        style: {
          colors: "#000",
          fontWeight: 500,
          fontSize: "14px",
        },
      },
    },
    tooltip: {
      fillSeriesColor: false,
      theme: "light",
    },
  };

  if (dataLabelFormatter && options.dataLabels) {
    options.dataLabels.formatter = dataLabelFormatter;
  }

  return (
    <Card {...cardProps} data-testid="StackedBarChart" title={title}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          flex: "1 1 100%",
        }}
      >
        {groupedSeries
          .flatMap((s) => s.series)
          .flatMap((x) => x.value)
          .reduce((acc, curr) => (acc ?? 0) + (curr ?? 0), 0) ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",

              alignItems: "center",
              flex: "1 1 100%",
            }}
          >
            {groupedSeries.map((gs, index) => (
              <Box
                key={`${index}stackedbarChart`}
                sx={{
                  background: gs.background,
                  borderRadius: 12,
                  py: 2,
                  width: fullWidth ? "100%" : undefined,
                }}
              >
                <ReactApexChart
                  type="bar"
                  width={fullWidth ? undefined : 250}
                  height={
                    ((gs.categories ?? categories)?.length ?? 10) *
                    (((gs.categories ?? categories)?.length ?? 0) < 4 ? 55 : 40)
                  }
                  options={{
                    ...options,
                    xaxis: {
                      ...options.xaxis,
                      categories: gs.categories ?? categories,
                    },
                  }}
                  series={gs.series.map((s) => ({
                    name: s.name,
                    data: s.value,
                  }))}
                />
              </Box>
            ))}
          </Box>
        ) : (
          <Box
            sx={{
              color: "grey",
              verticalAlign: "center",
              textAlign: "center",
              display: "flex",
              flex: "1 1 100%",
              flexDirection: "column",
              justifyContent: "center",
              p: 2,
            }}
          >
            <Typography sx={{ fontSize: 18 }}>N/A</Typography>
          </Box>
        )}
      </Box>
    </Card>
  );
};
