export const FORM_FIELD_INFO_TEXT = {
  initiativeDescription: `
Provide a detailed description about your initiative.  
Sample structure:  
To address [root cause], we are going to [action], so that [expected results].`,
  initiativePhase: `
Indicate what stage your initiative is currently in.  
Initiatives that do not yet have a start/end date should be marked as “Idea/Backlog” and an initiative should only be marked as “Done” once monitoring of benefits has concluded.`,
  itChangeRequired:
    "Please indicate if your initiative requires notable IT changes to be made",
  function:
    "Select the function that this initiative most applies to in terms of improvement",
  subFunction:
    "Subfunction describes the particular journey or process within a function. For example, within the Claims function there would be the First Notification of Loss sub function",
  initiativeOwner:
    "Name of the person or team responsible for implementing the initiative",
  implementationArm:
    "Overarching productivity improvement program that initiative uses the implementation resources of.  Use “Other” in case not part of existing program.",
  implementationArmUnit:
    "Specific branch of the implementation program (Optional)",
  kpiDashboardHyperlink: "Optional hyperlink to related dashboard",
  impactGrayBox: `
**Planned** – In line with the H4 KPI Dashboard, once a plan has been set, plans within an ongoing year cannot be changed.  
**Forecast** – Changes to plan within an ongoing year should be included as a forecast  
**Actual** – Each quarter, you will need to provide an updated figure on what has been realized. The INT will remind you and show you the status of your initiatives.

**Due dates for Actuals:** Q1 – 2nd Friday of April, Q2 – 3rd Friday of July, Q3 – 3rd Friday of October, Q4 – 4th Friday of January`,
  endDate:
    "End date should be the date where you plan to cease monitoring the impact of the initiative",
} as const;
