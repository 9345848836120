import { App, View } from "@pimo/pimo-app-builder";
import {
  ArtefactsProgressCard,
  CategoriesWithValuesInBadgesCard,
  DateCell,
  DerivePimoTableProps,
  IdentifierBadge,
  PimoTable,
  type PimoTableEventNames,
  type PimoTableEventPayload,
  PimoTableFilterEventPayload,
  PimoTableSortEventPayload,
  SortDirection,
  STATUS_TYPE,
  StatusCard,
  TabsLayoutProps,
  TextCardCell,
  TitleCard,
  Tooltip,
} from "@pimo/pimo-components";
import type {
  BffOeInitiatives,
  Initiative,
  InitiativeFilterData,
  InitiativeSortData,
  PhasesOverview,
} from "in-types";
import {
  ENABLERS,
  filterInitiatives,
  formatDate,
  getQuarterAndYear,
  IN_COLORS,
  LOCK_RESOURCE_NAMES,
  LOCK_RESOURCE_TEXTS,
  sortInitiatives,
} from "in-utils";
import { generatePath } from "react-router-dom";

import { Button, SxProps } from "@mui/material";
import { ResourceLockPlugin } from "@pimo/resource-lock-plugin";
import CommentsCard from "../../../components/comments-card/comments-card.tsx";
import { IconCell } from "../../../components/icon-cell/icon-cell.tsx";
import { InitiativesStatusTableContainer } from "../../../components/initiatives-status-table/initiatives-status-table-container.tsx";
import { InAppState } from "../../app.tsx";
import { APP_ROUTES } from "../../constants.ts";
import { getDraftIcon, getStatusIcon } from "../../utils/index.ts";

export function buildInitiativesView(
  view: View<InAppState, TabsLayoutProps>,
  app: App<InAppState>
) {
  const viewTitle = view.addComponent({
    component: TitleCard,
    layoutProps: {
      xs: 12,
      viewname: "initiatives",
    },
  });

  viewTitle.mapState((appState) => {
    const [currentQuarter, currentYear] = getQuarterAndYear();
    const currentOE = appState.currentOE;

    return {
      title: `Status Report Impact Navigator - ${
        currentOE?.operatingEntity ?? ""
      } (${currentQuarter}/${currentYear})`,
    };
  });

  const initiativePhasesCard = view.addComponent({
    component: CategoriesWithValuesInBadgesCard,
    layoutProps: {
      xs: 12,
      sm: 12 / 2,
      md: 12 / 4,
      viewname: "initiatives",
    },
  });

  initiativePhasesCard?.mapState((appState) => {
    const { phasesOverview, initiatives }: BffOeInitiatives =
      appState.currentOEInitiatives;
    const nonDraftInitiatives =
      initiatives?.filter(({ draft }) => !draft) ?? [];
    const phases = ["Planned", "In Progress", "Done"];
    const categories = phases.map((phase) => ({
      category: phase,
      value: `${phasesOverview?.[phase] ?? ""}/${
        nonDraftInitiatives?.length ?? ""
      }`,
    }));

    return {
      cardTitle: "Initiative Phases",
      chipBackgroundColor: [
        IN_COLORS.lightBlue,
        IN_COLORS.lightBlue,
        IN_COLORS.lightBlue,
      ],
      chipForegroundColor: IN_COLORS.white,
      categories: categories,
      cardIcon: "maturity.svg",
    };
  });

  const overallProgressCard = view.addComponent({
    component: ArtefactsProgressCard,
    layoutProps: {
      xs: 12,
      sm: 12 / 2,
      md: 12 / 4,
      viewname: "initiatives",
    },
  });

  overallProgressCard.mapState((appState) => {
    const { updateStatusOverview } = appState.currentOEInitiatives;
    const updateProgress = updateStatusOverview?.updateProgress ?? 0;

    return {
      cardIcon: "barchart.svg",
      cardTitle: "Overall Progress",
      color: IN_COLORS.lightBlue,
      displayValue: updateProgress.toString() + "%",
      value: updateProgress,
    };
  });

  const updatesCompletedCard = view.addComponent({
    component: CategoriesWithValuesInBadgesCard,
    layoutProps: {
      xs: 12,
      sm: 12 / 2,
      md: 12 / 4,
      viewname: "initiatives",
    },
  });

  updatesCompletedCard.mapState((appState) => {
    const { updateStatusOverview } = appState.currentOEInitiatives;
    const completionStatusByEnabler =
      updateStatusOverview?.completionStatusByEnabler ?? {};
    const updateStatus = updateStatusOverview?.updateStatus ?? {};

    return {
      cardTitle:
        "Updates Completed " +
        (updateStatus?.completed ? `(${updateStatus.completed})` : ""),
      cardIcon: "checklist.svg",
      chipBackgroundColor: [IN_COLORS.lightBlue, IN_COLORS.lightBlue],
      chipForegroundColor: IN_COLORS.white,
      categories: [
        {
          category: ENABLERS.AUTOMATION,
          value: completionStatusByEnabler?.[ENABLERS.AUTOMATION] ?? "",
        },
        {
          category: ENABLERS.PROCESS_MINING,
          value: completionStatusByEnabler?.[ENABLERS.PROCESS_MINING] ?? "",
        },
      ],
    };
  });

  const phasesCard = view.addComponent({
    component: StatusCard,
    layoutProps: {
      xs: 12,
      sm: 12 / 2,
      md: 12 / 4,
      viewname: "initiatives",
    },
  });

  phasesCard.mapState((appState) => {
    const { phasesOverview }: { phasesOverview?: PhasesOverview } =
      appState.currentOEInitiatives || {};
    const createStatus = (name: string, status: STATUS_TYPE) => ({
      count: phasesOverview ? phasesOverview[name] : 0,
      name: name,
      status: status,
    });

    return {
      isRow: false,
      statuses: [
        createStatus("Idea / Backlog", STATUS_TYPE.WHITE),
        createStatus("Planned", STATUS_TYPE.YELLOW),
        createStatus("In Progress", STATUS_TYPE.GREEN),
        createStatus("Done", STATUS_TYPE.COMPLETED),
      ],
      title: "Phase",
    };
  });

  const commentsCard = view.addComponent({
    component: CommentsCard,
    layoutProps: {
      xs: 12,
      viewname: "initiatives",
    },
  });

  commentsCard.mapState(({ currentOE, userProfile }) => {
    const lockDetail = currentOE?.lockDetails?.[LOCK_RESOURCE_NAMES.oeComments];
    const isLockedByOtherUser =
      lockDetail?.isLocked &&
      !!lockDetail.lockedBy &&
      !!userProfile?.email &&
      lockDetail.lockedBy !== userProfile.email;

    return {
      cardTitle: "Overall Comments On Initiatives",
      comments: currentOE?.comments || [],
      endSlot: (
        <Tooltip
          title={
            isLockedByOtherUser
              ? LOCK_RESOURCE_TEXTS.resourceCurrentlyLocked(
                  "comment",
                  lockDetail?.lockedBy ?? "unknown"
                )
              : ""
          }
        >
          <span>
            <Button
              disableElevation
              variant="outlined"
              disabled={isLockedByOtherUser}
              onClick={() => {
                const resourceLockPlugin =
                  app.getPluginByName<ResourceLockPlugin<InAppState>>(
                    "ResourceLockPlugin"
                  );

                if (!currentOE?.id) {
                  return;
                }

                void resourceLockPlugin?.lock(
                  `${LOCK_RESOURCE_NAMES.oeComments}-${currentOE?.id}`
                );

                app.navigate(
                  generatePath(APP_ROUTES.oeComments, {
                    oeId: currentOE?.id ?? "",
                    viewname: "initiatives",
                  })
                );
              }}
            >
              Edit {isLockedByOtherUser && "🔒"}
            </Button>
          </span>
        </Tooltip>
      ),
    };
  });

  const statusTableDefinition = [
    { component: IdentifierBadge },
    { component: TextCardCell },
    { component: DateCell },
    { component: DateCell },
    { component: TextCardCell },
    { component: TextCardCell },
    { component: TextCardCell },
    { component: DateCell },
    { component: IconCell },
  ] as const;

  const statusTable = new PimoTable(statusTableDefinition);

  const initiativesStatusTable = view.addComponent<
    DerivePimoTableProps<typeof statusTable>,
    PimoTableEventNames,
    PimoTableEventPayload
  >({
    component: statusTable,
    layoutProps: {
      viewname: "initiatives",
      xs: 12,
    },
  });

  initiativesStatusTable.on("sort", (event) => {
    const payload = event?.payload as PimoTableSortEventPayload<
      keyof Initiative
    >;
    const state = app.getAppState();
    let direction: SortDirection = "ASC";

    // Swap direction if key is already being sorted by in ascending order.
    if (
      state.initiativeSortData.key === payload?.sort?.key &&
      state.initiativeSortData.direction === "ASC"
    ) {
      direction = "DESC";
    }

    app.patchAppState({
      initiativeSortData: {
        key: payload?.sort?.key,
        direction,
      },
    });
  });

  initiativesStatusTable.on("filter", (event) => {
    const payload =
      event?.payload as PimoTableFilterEventPayload<InitiativeFilterData>;

    app.patchAppState({
      initiativeFilterData: {
        searchQueryFilter: payload?.filter?.searchQueryFilter ?? "",
        yearFilter: payload?.filter?.yearFilter ?? "",
        phaseFilter: payload?.filter?.phaseFilter ?? [],
        updateStatusFilter: payload?.filter?.updateStatusFilter ?? [],
      },
    });
  });

  initiativesStatusTable.mapState(
    ({
      initiativeSortData,
      initiativeFilterData,
      currentOE,
      currentOEInitiatives,
      userProfile,
    }) => {
      const initiatives = filterInitiatives(
        initiativeFilterData,
        currentOEInitiatives?.initiatives || []
      );
      const oeId = currentOE?.id ?? "";
      return {
        container: InitiativesStatusTableContainer,
        containerProps: {
          title: "Initiatives",
          lockDetail: {
            ...currentOE?.lockDetails?.[LOCK_RESOURCE_NAMES.oeInitiatives],
            ownEmail: userProfile?.email,
          },
          lockInitiativesForOE: () => lockInitiativesForOE(oeId),
          addInitiativePath: generatePath(APP_ROUTES.addInitiative, {
            oeId,
            viewname: "initiatives",
          }),
          editInitiativePath: generatePath(APP_ROUTES.editInitiatives, {
            oeId: oeId ?? "",
            initiativeId: String(initiatives[0]?.uid) ?? "new",
            viewname: "initiatives",
          }),
          filterData: initiativeFilterData,
        },
        data: sortInitiatives(initiativeSortData, initiatives).map(
          (initiative) => {
            const {
              endDate,
              impactValue,
              initiativeName,
              initiativeOwner,
              initiativePhase,
              startDate,
              uid,
              lastUpdatedAt,
              lastUpdatedBy,
              updateStatus,
              draft,
            } = initiative;
            const statusIcon = updateStatus ? getStatusIcon(updateStatus) : "";
            const draftIcon = getDraftIcon();

            const formattedImpactValue = impactValue?.toLocaleString("en-US");
            const columnProps = [
              {
                children: uid,
              },
              {
                body: initiativeName,
              },
              {
                date: startDate?.toString()?.slice(0, 10) ?? "",
              },
              {
                date: endDate?.toString()?.slice(0, 10) ?? "",
              },
              {
                body: impactValue != null ? `${formattedImpactValue} €` : "n/a",
              },
              {
                body: initiativeOwner ?? "",
              },
              {
                body: initiativePhase ?? "",
              },
              {
                date: formatDate(lastUpdatedAt) ?? "",
              },
              {
                value: draft ? draftIcon : statusIcon,
                title: [
                  lastUpdatedAt && `as of ${formatDate(lastUpdatedAt)}`,
                  lastUpdatedBy && `by ${lastUpdatedBy}`,
                ]
                  .filter(Boolean)
                  .join("\n"),
              },
            ] as const satisfies DerivePimoTableProps<
              typeof statusTable
            >["data"][number]["columnProps"];

            const currentOE = app.getAppState().currentOE;
            const lockDetail =
              currentOE?.lockDetails?.[LOCK_RESOURCE_NAMES.oeInitiatives];

            const isLockedByOtherUser =
              lockDetail?.isLocked &&
              !!lockDetail.lockedBy &&
              !!userProfile?.email &&
              lockDetail.lockedBy !== userProfile.email;

            return {
              columnProps,
              rowProps: {
                cardProps: {
                  sx: { cursor: isLockedByOtherUser ? "default" : "pointer" },
                },
                onClick: () => {
                  const currentOE = app.getAppState().currentOE;

                  if (!currentOE || isLockedByOtherUser) {
                    return;
                  }

                  void lockInitiativesForOE(currentOE.id);

                  app.navigate(
                    generatePath(APP_ROUTES.editInitiatives, {
                      oeId: String(currentOE.id),
                      viewname: "initiatives",
                      initiativeId: initiative.uid,
                    })
                  );
                },
              },
            };
          }
        ),
        tableHeaderEntries: generateTableHeaderEntries({
          entries: [
            {
              fieldName: "uid",
              label: "ID",
            },
            {
              fieldName: "initiativeName",
              label: "Initiative",
            },
            {
              fieldName: "startDate",
              label: "Start Date",
            },
            {
              fieldName: "endDate",
              label: "End Date",
            },
            {
              fieldName: "impactValue",
              label: "Total Impact",
            },
            {
              fieldName: "initiativeOwner",
              label: "Initiative Owner",
            },
            {
              fieldName: "initiativePhase",
              label: "Phase",
            },
            {
              fieldName: "lastUpdatedAt",
              label: "Last Updated",
            },
            {
              fieldName: "updateStatus",
              label: "Quarterly Update Complete",
              sx: {
                justifyContent: "center",
              },
            },
          ],
          initiativeSortData,
        }),
      };
    }
  );
  function generateTableHeaderEntries({
    entries,
    initiativeSortData,
  }: {
    entries: { fieldName: keyof Initiative; label: string; sx?: SxProps }[];
    initiativeSortData: InitiativeSortData;
  }) {
    return entries.map(({ fieldName, label, sx }) => ({
      fieldName,
      label,
      sx,
      sort:
        initiativeSortData.key === fieldName
          ? initiativeSortData.direction
          : null,
    }));
  }

  function lockInitiativesForOE(oeId: string) {
    const resourceLockPlugin =
      app.getPluginByName<ResourceLockPlugin<InAppState>>("ResourceLockPlugin");

    return resourceLockPlugin?.lock(
      `${LOCK_RESOURCE_NAMES.oeInitiatives}-${oeId}`
    );
  }
}
