export const APP_ROUTES = {
  home: "/",
  login: "/login",
  operatingEntities: "/operating-entities/:oeId/:viewname",
  oeComments: "/operating-entities/:oeId/:viewname/comments",
  editInitiatives: "/operating-entities/:oeId/:viewname/:initiativeId",
  addInitiative: "/operating-entities/:oeId/:viewname/new",
  editImpactPlan: "/operating-entities/:oeId/:viewname/edit-impact-plan",
  dashboard: "/dashboard",
  overview: "/overview",
} as const;
