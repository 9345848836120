import { FormControl, TextField, TextFieldProps } from "@mui/material";
import {
  CalendarPickerView,
  DatePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { PimoReactComponent } from "@pimo/pimo-app-builder";
import { Dayjs } from "dayjs";

export interface DateFilterProps {
  views: CalendarPickerView[];
  value: string;
  label: string;
  triggerIdentifier: string;
  handleChange: (
    e: React.ChangeEvent<HTMLInputElement> | { target: { value: string } },
    trigger: string
  ) => void;
}

export const DateFilter: PimoReactComponent<DateFilterProps> = ({
  views,
  value,
  label,
  handleChange,
  triggerIdentifier,
}) => {
  return (
    <FormControl>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          value={value === "" ? null : value}
          openTo={
            views.includes("day") || views.includes("month") ? "day" : "year"
          }
          label={label}
          views={views}
          onChange={(val: Dayjs | null) => {
            const event = {
              target: {
                value: String(val?.year() ?? ""),
              },
            };

            handleChange(event, triggerIdentifier);
          }}
          renderInput={(params: TextFieldProps) => <TextField {...params} />}
        />
      </LocalizationProvider>
    </FormControl>
  );
};
